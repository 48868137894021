<template>
	<a-modal v-model="show" title="车辆信息" :width="700" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model ref="formRef" :model="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="6" v-if="getOrganId() == 0">
					<a-form-model-item label="所属单位" label-position="top" prop="organId">
						<a-select v-model="formData.organId" style="width:100%" placeholder="请选择单位">
							<a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{ organ.name
							}}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="7">
					<a-form-model-item label="车牌号" label-position="top" prop="carNumber">
						<a-input v-model="formData.carNumber" placeholder="请输入车牌号"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label="车辆类别" label-position="top" prop="type">
						<a-select v-model="formData.type" style="width:100%" placeholder="请选择车辆类别">
							<a-select-option :value="car.categoryId" v-for="car in typeList" :key="car.categoryId">{{
								car.categoryName
							}}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="8">
					<a-form-model-item label="购买日期" label-position="top" prop="buyDate">
						<a-date-picker v-model="formData.buyDate" show-time type="date" placeholder="请输入购买日期" />
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="购买价格" label-position="top" prop="buyPrice">
						<a-input v-model="formData.buyPrice" type="number" placeholder="请输入购买价格"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="状态" label-position="top" prop="status">
						<a-select v-model="formData.status" style="width:100%" placeholder="请选择车辆状态">
							<a-select-option :value="status" v-for="(status, index) in statusList" :key="index">{{ status
							}}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="8">
					<a-form-model-item label="备注信息" label-position="top">
						<a-input v-model="formData.remark" placeholder="请输入备注信息"></a-input>
					</a-form-model-item>
				</a-col>

				<a-col span="8">
					<a-form-model-item label="发动机号" label-position="top">
						<a-input v-model="formData.coreNumber" type="number" placeholder="请输入发动机号"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label="驾驶员" label-position="top">
						<a-input v-model="formData.driver" placeholder="请输入驾驶员名称"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<up-img ref="upimg" :thumb="formData.thumb"></up-img>
				</a-col>
			</a-row>
		</a-form-model>
		<a-spin size="large" fix v-if="loading" />
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import upImg from '@/components/common/upImg.vue';

export default {
	components: { upImg },
	data() {
		return {
			show: false,
			loading: false,
			upimg: this.$refs.upimg,
			typeList: [],
			statusList: ['损坏', '维修中', '报废', '正常'],
			organList: apiUtil.getOrganList(),
			formData: {
				carNumber: '',
				type: '',
				buyDate: '',
				buyPrice: '',
				coreNumber: '',
				driver: '',
				organId: apiUtil.getOrganId(''),
				remark: '',
				status: "正常",
				thumb: ''
			},
			formRule: {
				carNumber: [{ required: true, message: '请输入车牌号' }],
				type: [{ required: true, message: '请选择车辆类别' }],
				status: [{ required: true, message: '请选择车辆状态' }],
				buyDate: [{ required: true, message: '请输入购买日期' }],
				buyPrice: [{ required: true, message: '请输入购买日期' }]
			}
		};
	},
	methods: {
		...apiUtil,
		_show(form) {
			this.show = true;
			this.getCategory();
			this.$nextTick(() => {
				this.$refs.formRef.resetFields();
				if (form) {
					delete form.carCategory;
					this.formData = JSON.parse(JSON.stringify(form));
				} else {
					this.formData.carNumber = '';
					this.formData.type = '';
					this.formData.buyDate = '';
					this.formData.buyPrice = '';
					this.formData.coreNumber = '';
					this.formData.driver = '';
					this.formData.organId = apiUtil.getOrganId('');
					this.formData.remark = '';
					this.formData.status = "正常";
					this.formData.thumb = '';
				}
			});
		},
		_close() {
			this.$refs.formRef.resetFields();
			this.show = false;
			this.$emit('close');
		},
		getCategory() {
			this.http
				.get(`/platform/CarCategory/list`, { organId: this.organId })
				.then(ret => {
					this.typeList = ret.data;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		doSubmit() {
			this.$refs.formRef.validate(valid => {
				if (valid) {
					utils.showSpin();
					this.formData.thumb = this.$refs.upimg.img;
					request.post('/platform/car/save', this.formData).then(ret => {
						this.show = false;
						if (ret.code == 200) {
							utils.success('操作成功！').then(() => {
								this.$emit('success');
								// this.$refs.formRef.resetFields();
								this.show = false;
							});
						}
					});
				}
			});
		}
	},
	watch: {
		upimg(newdata, olddata) {
			console.log(newdata);
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}

.avatar-uploader>.ant-upload {
	width: 128px;
	height: 128px;
}

.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}
</style>
